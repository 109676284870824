<template>
    <div class="specific-product-area" v-if="!loading">
        <router-link class="back-previous-screen" to="/business/dashboard/product-areas">
            {{ "< " + $t("Back to [Previous Screen]", { previousScreen: $t("Product Areas") }) }}
        </router-link>

        <el-select
            v-model="productArea"
            class="product-area-select"
            :disabled="!specificProductAreaDataContainer"
            @change="handleProductAreaChange"
        >
            <el-option
                v-for="(item, index) in businessProductAreas"
                :key="index"
                :label="item"
                :value="item"
            />
        </el-select>

        <SpecificProductAreaFilter
            ref="specificProductAreaFilter"
            :class="{'disabled-filter': !specificProductAreaDataContainer}"
            :dashboardSingleProductAreaFiltersState="dashboardSingleProductAreaFiltersState"
            :disabled="!specificProductAreaDataContainer"
            @onApplyFilters="fetchProductAreasData"
        />

        <template v-if="specificProductAreaDataContainer">
            <div class="specific-product-area__score">
                <div class="product-area-score">
                    <DashboardScore
                        :title="$t('Product Area Score')"
                        :description="$t('Based on all your Banks/filters applied')"
                        :fillData="specificProductAreaDataContainer.scoreSectionData"
                    />
                </div>
                <div class="product-area-chart">
                    <LineChart :chartData="specificProductAreaDataContainer.dotChartSectionData" />
                </div>
            </div>

            <div class="specific-product-area__list">
                <div class="list-section-item">
                    <DashboardCommonList
                        list-item-has-icon
                        hide-footer
                        hide-view-all
                        :filter-data="dashboardSingleProductAreaFiltersState"
                        :page-type="BusinessCommonListEnum.BANKS"
                        :dataContainer="specificProductAreaDataContainer"
                        :score-data="specificProductAreaDataContainer.bankSectionData"
                    />
                </div>
                <div class="list-section-item">
                    <DashboardCommonList
                        hide-footer
                        hide-view-all
                        :filter-data="dashboardSingleProductAreaFiltersState"
                        :page-type="BusinessCommonListEnum.COUNTRIES"
                        :dataContainer="specificProductAreaDataContainer"
                        :score-data="specificProductAreaDataContainer.countrySectionData"
                    />
                </div>
            </div>

            <div class="specific-product-area__questions-list">
                <QuestionsTabsContainer
                    :benchmark-questions-list="specificProductAreaDataContainer.questionsSectionData.questions"
                    :custom-questions="specificProductAreaDataContainer.customQuestionsSectionData"
                    :is-loading="specificProductAreaDataContainer.questionsSectionData.isLoading"
                    @onSortingChange="handleSortingChange"
                    @onClickQuestionItem="handleClickQuestionItem"
                    @onTabChange="questionActiveTab = $event"
                />

                <CustomPagination
                    v-if="specificProductAreaDataContainer.questionsSectionData.pagination &&
                        questionActiveTab === QUESTIONS_TABS.BENCHMARK_QUESTIONS"
                    class="mt-2"
                    :current-page="specificProductAreaDataContainer.questionsSectionData.page"
                    :total="specificProductAreaDataContainer.questionsSectionData.pagination.totalItems"
                    :pageSize="specificProductAreaDataContainer.questionsSectionData.limit"
                    @currentPageChange="handleCurrentPageChange"
                    @pageSizeChange="handlePageSizeChange"
                />
            </div>
        </template>
        <CustomLoader v-else />
    </div>
</template>

<script>
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import SpecificProductAreaFilter from "@/buying-teams/pages/business/dashboard/components/SpecificProductAreaFilter";
import DashboardScore from "@/buying-teams/pages/business/dashboard/components/overview/DashboardScore";
import DashboardCommonList from "@/buying-teams/shared-components/dashboard/DashboardCommonList";
import LineChart from "@/buying-teams/shared-components/charts/LineChart";
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";
import { diagLog } from "@/core/helpers/GlobalHelper";
import {
    SpecificProductAreaDataContainer
} from "@/store/models/business/dashboard/level3/SpecificProductAreaDataContainer";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";
import QuestionsTabsContainer from "@/buying-teams/shared-components/questions/QuestionsTabsContainer";
import { QUESTIONS_TABS } from "@/core/config/constants";

export default {
    name: "SpecificProductArea",
    components: {
        QuestionsTabsContainer,
        CustomLoader,
        CustomPagination,
        DashboardCommonList,
        DashboardScore,
        SpecificProductAreaFilter,
        LineChart
    },
    data() {
        return {
            loading: true,
            BusinessCommonListEnum,
            specificProductAreaDataContainer: null,
            productArea: "",
            QUESTIONS_TABS,
            questionActiveTab: QUESTIONS_TABS.BENCHMARK_QUESTIONS,
        };
    },
    computed: {
        businessData() {
            return store.getters.business;
        },
        businessProductAreas() {
            return store.getters.businessProductAreas;
        },
        dashboardSingleProductAreaFiltersState() {
            return store.getters.getBusinessFiltersState.dashboardSingleProductArea
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.businessData.name,
            logo: this.businessData.icon_path
        });
    },
    beforeMount() {
        this.productArea = this.$route.params.productArea;
        if (!this.productArea) {
            redirectToRouteWithName("business-dashboard-product-areas");
        }
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.DASHBOARD_SINGLE_PRODUCT_AREA);
        this.loading = false;
        await this.fetchProductAreasData(this.dashboardSingleProductAreaFiltersState);
    },
    methods: {
        handleCurrentPageChange(page) {
            this.specificProductAreaDataContainer.questionsSectionData.page = page;
            this.fetchQuestionsData();
        },
        handlePageSizeChange(size) {
            this.specificProductAreaDataContainer.questionsSectionData.page = 1;
            this.specificProductAreaDataContainer.questionsSectionData.limit = size;
            this.fetchQuestionsData();
        },
        setFiltersDefaultState() {
            this.$refs.specificProductAreaFilter.setFiltersDefaultState();
        },
        handleProductAreaChange(e) {
            this.$router.replace({ name: "business-dashboard-specific-product-areas", params: { productArea: e } });
            this.setFiltersDefaultState();
            this.$refs.specificProductAreaFilter.setFilterApplied();
            this.fetchProductAreasData(this.$refs.specificProductAreaFilter.filterData || this.dashboardSingleProductAreaFiltersState);
        },
        handleSortingChange(sorting) {
            this.specificProductAreaDataContainer.questionsSectionData.page = 1;
            this.specificProductAreaDataContainer.questionsSectionData.orderKey = sorting;
            this.specificProductAreaDataContainer.addQuestionsSectionData([], null,  true);
            this.fetchQuestionsData();
        },
        async handleClickQuestionItem(question, newTab) {
            await store.dispatch('moveQuestionFilters', {
                type: BusinessCommonListEnum.PRODUCT_AREA,
                pageFilterData: this.dashboardSingleProductAreaFiltersState,
                itemId: this.productArea
            });

            const questionUrl = `/business/questions/${question.id}${question.isCustom ? '?isCustom=true' : ''}`;

            if (newTab) {
                window.open(questionUrl, '_blank');
            } else {
                this.$router.push(questionUrl);
            }
        },
        async fetchProductAreasData(filter = null) {
            try {
                this.specificProductAreaDataContainer = null;
                let res = await store.dispatch("fetchSpecificProductAreaData", {
                    filter,
                    productArea: this.productArea
                });
                this.specificProductAreaDataContainer = new SpecificProductAreaDataContainer(res);

                this.fetchQuestionsData();
                diagLog("PAGE DATA ", this.specificProductAreaDataContainer);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchQuestionsData() {
            if (this.specificProductAreaDataContainer.questionsSectionData.isLoading) {
                return;
            }

            this.specificProductAreaDataContainer.questionsSectionData.isLoading = true;
            try {
                let payload = {
                    page: this.specificProductAreaDataContainer.questionsSectionData.page,
                    limit: this.specificProductAreaDataContainer.questionsSectionData.limit,
                    order_key: this.specificProductAreaDataContainer.questionsSectionData.orderKey,
                    order_direction: this.specificProductAreaDataContainer.questionsSectionData.orderDirection,
                    filter: {
                        ...this.$refs.specificProductAreaFilter.filterData,
                        product_areas: [this.$route.params.productArea]
                    },
                    type: "sub_menu"
                };

                let res = await store.dispatch("fetchBusinessDashboardQuestions", payload);
                diagLog(`fetched res for page ${payload.page}`, { res, payload });
                this.specificProductAreaDataContainer.addQuestionsSectionData(res.questions, res.paginationMetadata);
                this.specificProductAreaDataContainer.customQuestionsSectionData.setCustomQuestionsAnalyticsData(res.customQuestions);
            } catch (e) {
                console.error({ e });
            } finally {
                this.specificProductAreaDataContainer.questionsSectionData.isLoading = false;
            }
        }
    }

};
</script>

<style lang="scss">
.specific-product-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .specific-product-area-filter {
        &.disabled-filter {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 4;
                background: #F6F6F6;
                opacity: 0.5;
                border-radius: 17px;
            }
        }
    }

    .section-card {
        background: #FFFFFF;
        box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 17px;

        .section-card-header {
            height: 62px;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .section-card-title {
            display: flex;
            align-items: center;
            gap: 8px;

            h2 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
            }

            span {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
            }
        }

        .section-card-actions {
            display: flex;
            align-items: center;
            gap: 22px;

            > a {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #435BF4;
                opacity: 0.87;

                &:hover {
                    opacity: 1;
                }
            }

            &__sort {
                width: 28px;
                height: 28px;
                background: transparent;
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .product-area-select {
        margin-top: 10px;

        .el-input__inner {
            padding-left: 10px;
            height: 33px;
            border-bottom: 2px solid #363636;
            border-radius: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 13px;
            color: rgba(0, 0, 0, 0.87);
            width: 276px;
        }

        .el-input__suffix svg path {
            fill: #000000;
        }
    }

    &__score {
        display: flex;
        gap: 12px;
        width: 100%;

        .product-area-score {
            width: 338px;
        }

        .product-area-chart {
            width: calc(100% - 350px);
            overflow: hidden;
            background: #FFFFFF;
            box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
            border-radius: 17px;
            display: flex;
            align-items: center;

            .line-chart {
                width: 100%;
            }

            .chart-empty-state {
                width: 100%;
            }
        }
    }

    &__list {
        width: 100%;
        display: flex;
        gap: 12px;

        .list-section-item {
            width: 50%;
        }
    }

    &__questions-list {
        width: 100%;
    }

    @media (max-width: 1200px) {
        &__list {
            flex-direction: column;

            .list-section-item {
                width: 100%;
            }
        }
        &__score {
            flex-direction: column;

            .product-area-score, .product-area-chart {
                width: 100%;
            }
        }
    }
}
</style>
