import { BusinessCommonListItem } from "@/store/models/shared/BusinessCommonListItem";
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { BusinessDashboardOrderDirectionsEnum } from "@/store/enums/business/BusinessDashboardOrderDirectionsEnum";
import { QuestionModel } from "@/store/models/bank/CompareProductAreaModel";
import {
    SpecificQuestionCommentItemStats
} from "@/store/models/business/dashboard/level4/SpecificQuestionCommentItemStats";
import {CustomQuestionsAnalyticsContainer} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsContainer";
import { BankCommonListEnum } from "@/store/enums/BankCommonListEnum";

export class SpecificPageDataContainerBase {
    dotChartSectionData: any;
    countrySectionData: any;
    productAreaSectionData: any;
    bankSectionData: any;

    questionsSectionData: any = {
        questions: [],
        isLoading: false,
        pagination: null,
        page: 1,
        limit: 50,
        orderKey: 'lowest_score',
        orderDirection: BusinessDashboardOrderDirectionsEnum.DESC,
    };

    commentsSectionData: any = {
        comments: [],
        isLoading: false,
        pagination: null,
        page: 1,
        limit: 50,
        orderKey: 'lowest_score',
        orderDirection: BusinessDashboardOrderDirectionsEnum.DESC,
    };

    customQuestionsSectionData: CustomQuestionsAnalyticsContainer = new CustomQuestionsAnalyticsContainer();

    constructor(obj: any, isBankSide: boolean = false) {
        diagLog(this, 'OBJ', obj);

        this.dotChartSectionData = obj.dot_chart;

        this.countrySectionData = this.getCountriesSectionData(obj.country_scores, isBankSide);
        this.productAreaSectionData = this.getProductAreasSectionData(obj.product_area_scores, isBankSide);
        this.bankSectionData = this.getBankSectionData(obj.bank_scores);
    }

    getCountriesSectionData(countriesData: any, isBankSide) {
        let res = [] as any[];

        if(countriesData) {
            return countriesData.map(cnt => new BusinessCommonListItem({
                ...cnt,
                type: isBankSide ? BankCommonListEnum.COUNTRIES : BusinessCommonListEnum.COUNTRIES,
                title: cnt.country,
                stats: {
                    score: cnt.score,
                    importance: cnt.importance,
                    gap: cnt.gap,
                    scores: cnt.scores || 0,
                }
            }));
        }

        return res;
    }

    getProductAreasSectionData(productAreasData: any, isBankSide) {
        let res = [] as any[];

        if(productAreasData) {
            return productAreasData.map(prd => new BusinessCommonListItem({
                ...prd,
                type: isBankSide ? BankCommonListEnum.PRODUCT_AREA : BusinessCommonListEnum.PRODUCT_AREA,
                title: prd.product_area,
                stats: {
                    score: prd.score,
                    importance: prd.importance,
                    gap: prd.gap,
                    scores: prd.scores || 0,
                }
            }));
        }

        return res;
    }

    getBankSectionData(banksData: any) {
        let res = [] as any[];

        if(banksData) {
            return banksData.map(bnk => new BusinessCommonListItem({
                ...bnk,
                type: BusinessCommonListEnum.BANKS,
                title: bnk.name,
                stats: {
                    score: bnk.score,
                    importance: bnk.importance,
                    gap: bnk.gap,
                    scores: bnk.scores || 0,
                }
            }));
        }

        return res;
    }

    addQuestionsSectionData (questionData, paginationData) {
        this.questionsSectionData.pagination = paginationData;
        this.questionsSectionData.questions = questionData.map(dt => {
            return new QuestionModel({
                ...dt,
                question_id: dt.question_id,
                id: dt.question_id,
            })
        })
    }

    addCommentsSectionData (questionData, paginationData) {
        this.commentsSectionData.pagination = paginationData;
        this.commentsSectionData.comments = questionData.map(dt => new SpecificQuestionCommentItemStats(dt));
    }

    handleSorting(pageType, sorting) {
        let dataForSorting;

        switch (pageType) {
            case BusinessCommonListEnum.BANKS:
                dataForSorting = this.bankSectionData;
                break;
            case BusinessCommonListEnum.PRODUCT_AREA:
            case BankCommonListEnum.PRODUCT_AREA:
                dataForSorting = this.productAreaSectionData;
                break;
            case BusinessCommonListEnum.COUNTRIES:
            case BankCommonListEnum.COUNTRIES:
                dataForSorting = this.countrySectionData;
                break;
        }

        if (dataForSorting) this.sort(dataForSorting, sorting);
    }

    sort(data, sorting) {
        switch (sorting) {
            case 'highest_score':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li2.stats.score - li1.stats.score);
                break;
            case 'lowest_score':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li1.stats.score - li2.stats.score);
                break;
            case 'highest_importance_score':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li2.stats.importance - li1.stats.importance);
                break;
            case 'lowest_importance_score':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li1.stats.importance - li2.stats.importance);
                break;
            case 'highest_negative_gap':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li1.stats.gap - li2.stats.gap);
                break;
            case 'highest_positive_gap':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li2.stats.gap - li1.stats.gap);
                break;
        }
    }
}
