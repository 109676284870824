<template>
  <CustomerChartEmptyState v-if="isEmptyState" />
  <div class="line-chart" v-else>
    <apexchart
      width="100%"
      height="265"
      type="area"
      :options="{ ...options }"
      :series="series"
    />
  </div>
</template>

<script>
import Apexchart from "vue3-apexcharts";
import { options } from "@/buying-teams/shared-components/charts/data";
import CustomerChartEmptyState from "@/buying-teams/pages/bank/customers/components/CustomerChartEmptyState";

const labelNames = {
  score: 'Score',
  importance: 'Importance'
}

export default {
  name: "Chart",
  components: {
    CustomerChartEmptyState,
    Apexchart
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      series: [],
      isEmptyState: false,
      options
    };
  },
  created() {
    const dayData = new Set();
    this.chartData.forEach(val => dayData.add(new Date(val.date).getDate()))
    if (dayData.size <= 1) {
      this.isEmptyState = true;
    } else {
      this.isEmptyState = false;
      this.setChartSeries();
    }
  },
  methods: {
    setChartSeries() {
      let data = [];
      let names = ['score', 'importance']
      let series = this.chartData.map(val => {
        return {
          score: val.score,
          importance: val.importance,
          date: val.date
        }
      })
      names.forEach(name => {
        data.push({
          name: this.$t(labelNames[name]),
          data: series.map(item => {
            return {
              x: item.date,
              y: item[name]
            }
          })
        })
      })

      this.series = data
    }
  }
};
</script>

<style lang="scss" >
 .labels-checked{
   .apexcharts-legend{
     margin-top: 50px;
   }
 }
 .line-chart{
   .apexcharts-canvas{
     width: 100% !important;
     .apexcharts-svg {
       width: 100%;
     }
   }

 }
</style>
