import { numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export const series = [
  {
    name: "Score",
    data: [
      { x: "2021-05-27", y: 10 },
      { x: "2021-05-26", y: 2 },
      { x: "2021-05-25", y: 8 },
      { x: "2021-05-24", y: 4 },
      { x: "2021-05-23", y: 5 },
      { x: "2021-05-22", y: 6 },
      { x: "2021-05-21", y: 22 },
    ],
  },
  {
    name: "Importance",
    data: [
      { x: "2021-05-27", y: 1 },
      { x: "2021-05-26", y: 22 },
      { x: "2021-05-25", y: 35 },
      { x: "2021-05-24", y: 4 },
      { x: "2021-05-23", y: 8 },
      { x: "2021-05-22", y: 6 },
      { x: "2021-05-21", y: 1 },
    ],
  },
  {
    name: "Negative Gap",
    data: [
      { x: "2021-05-27", y: 21 },
      { x: "2021-05-26", y: 6 },
      { x: "2021-05-25", y: 9 },
      { x: "2021-05-24", y: 12 },
      { x: "2021-05-23", y: 5 },
      { x: "2021-05-22", y: 10 },
      { x: "2021-05-21", y: 14 },
    ],
  },
  {
    name: "Positive Gap",
    data: [
      { x: "2021-05-27", y: 11 },
      { x: "2021-05-26", y: 2 },
      { x: "2021-05-25", y: 12 },
      { x: "2021-05-24", y: 41 },
      { x: "2021-05-23", y: 18 },
      { x: "2021-05-22", y: 0 },
      { x: "2021-05-21", y: 12 },
    ],
  },
];

export const options = {
  chart: {
    foreColor: "#757575",
    toolbar:{
      show:false
    },
  },
  grid: {
    show: true,
    borderColor: '#CFCAD9',
    strokeDashArray: [2, 6],
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: true,
      }
    },
  },
  legend: {
    show: true,
    position:"bottom",
    horizontalAlign: "left",
    fontSize: "16px",
    fontWeight: 500,
    labels: {
      colors: "#717171",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    curve: "straight",
    width: 2,
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeFormatter: {
        month: "MMMM",
        day: "dd MMM",
      },
      style: {
        fontSize: "10px",
      },
    },
  },
  yaxis: {
    min: 0,
    max: 5,
    labels: {
      formatter: (val) =>
        Number.isInteger(val) ? Math.round(val) : val.toFixed(1),
      style: {
        fontSize: "10px",
      },
    },
  },
  tooltip: {
    y: {
      formatter: (val) => numberDecimalsFormat(val)
    }
  },
  colors: ["#0b2af1",'#CED4F8'],
  fill: {
    type: "gradient",
    colors: ["#0b2af1",'#CED4F8'],
    gradient: {
      type: "vertical",
      shadeIntensity: 0,
      shade: "dark",
      opacityFrom: 0.3,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
};
