<template>
  <div class="chart-empty-state">
    <img src="/media/buying/customer-empty-state.png" height="280" alt="">
    <div class="chart-empty-state__content">
      <img src="/media/buying/icons/pending-more-data.svg" alt="">
      <h2>{{ $t('Pending more data') }}</h2>
      <p>{{ $t('There is not enough data to show you development over time. Please check back later.') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerChartEmptyState"
}
</script>

<style scoped lang="scss">
.chart-empty-state {
  position: relative;
  border-radius: 12px;
  > img {
    max-width: 100%;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4px);
    overflow: hidden;
    margin: -32px;
    border-radius: 12px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 20px;
    }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #434343;
      margin-bottom: 16px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #757575;
      white-space: pre-line;
    }
  }
}
</style>
