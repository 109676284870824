import { SpecificPageDataContainerBase } from "@/store/models/shared/SpecificPageDataContainerBase";
import CircleChartModel from "@/store/models/CircleChartModel";

export class SpecificProductAreaDataContainer extends SpecificPageDataContainerBase {
    scoreSectionData: any;

    constructor(obj: any) {
        super(obj);

        this.scoreSectionData = {
            chartData: new CircleChartModel(obj.stats),
            respondents: obj.stats.respondents,
            scores: obj.stats.scores || 0
        };
    }
}
