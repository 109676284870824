import { UserModelMin } from "@/store/models/shared/UserModelMin";
import { BankModelMin } from "@/store/models/bank/BankModelMin";

export class SpecificQuestionCommentItemStats {
    userData: UserModelMin;
    question: any;
    stats: any;
    comment: any;
    commenter = null as BankModelMin | null;

    constructor(obj: any) {
        this.userData = new UserModelMin(obj.user_data);
        this.question = obj.question;
        this.stats = obj.stats;
        this.comment = obj.comment_data;
        if (obj.comment_data) {
            this.commenter = new BankModelMin({
                name: obj.comment_data.bank,
                icon_path: obj.comment_data.icon_path
            })
        }
    }
}
